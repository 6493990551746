import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './auth-provider'
import { useSites } from './sites-provider'
import { useLegalEntity } from './legal-entity-provider'
import { LoadingCircleProgress } from 'components/Utilities/progress'
import {
  fetchSegments,
  fetchSegmentsCategoryTrees,
  fetchSegmentsAssignedProducts,
} from '../services/segments'
import { TENANT } from 'constants/localstorage'

const initialContext = {
  segments: [],
}
export const SEGMENTS_KEY = 'segments'
export const SEGMENTS_CATEGORY_TREES_KEY = 'segments-category-trees'
export const SEGMENTS_ASSIGNED_PRODUCTS_KEY = 'segments-assigned-products'

const SegmentContext = createContext(initialContext)
export const useSegments = () => useContext(SegmentContext)

export const SegmentProvider = ({ children }) => {
  const [segments, setSegments] = useState([])
  const [segmentAssignedProducts, setSegmentAssignedProducts] = useState([])
  const [segmentCategoryTrees, setSegmentCategoryTrees] = useState([])
  const { isLoggedIn, userTenant } = useAuth()
  const { currentLegalEntity, currentBusinessModel} = useLegalEntity()
  const [loading, setLoading] = useState(true)
  const { currentSite } = useSites()
  const tenant = localStorage.getItem(TENANT)

  useEffect(() => {
    if (!isLoggedIn) {
      setSegments([])
      setSegmentCategoryTrees([])
      setSegmentAssignedProducts([])
      localStorage.setItem(SEGMENTS_ASSIGNED_PRODUCTS_KEY, null)
      localStorage.setItem(SEGMENTS_CATEGORY_TREES_KEY, null)
      localStorage.setItem(SEGMENTS_KEY, null)
    }
  }, [isLoggedIn])

  useEffect(() => {
    ;(async () => {
      try {
        if (isLoggedIn && userTenant && currentBusinessModel) {
          fetchUserSegments()
          fetchCategoryTrees()
          fetchAssignedProducts()
        }
      } catch (error) {
        console.error('Error during fetching segments:', error)
      } finally {
        setLoading(false)
      }
    })()
  }, [currentSite, isLoggedIn, currentLegalEntity, currentBusinessModel])

  const fetchUserSegments = async () => {
    const segments = await fetchSegments(
      tenant,
      currentSite,
      currentLegalEntity
    )
    setSegments(segments)
    localStorage.setItem(SEGMENTS_KEY, JSON.stringify(segments))
  }

  const fetchCategoryTrees = async () => {
    const categoryTrees = await fetchSegmentsCategoryTrees(
      tenant,
      currentSite,
      currentLegalEntity
    )
    setSegmentCategoryTrees(categoryTrees)
    localStorage.setItem(SEGMENTS_CATEGORY_TREES_KEY, JSON.stringify(categoryTrees))
  }

  const fetchAssignedProducts = async () => {
    const assignedProducts = await fetchSegmentsAssignedProducts(
      tenant,
      currentSite,
      currentLegalEntity
    )
    setSegmentAssignedProducts(assignedProducts)
    localStorage.setItem(SEGMENTS_ASSIGNED_PRODUCTS_KEY, JSON.stringify(assignedProducts))
  }

  if (loading) {
    return <LoadingCircleProgress />
  }

  return (
    <SegmentContext.Provider
      value={{
        segments,
        segmentAssignedProducts,
        segmentCategoryTrees,
      }}
    >
      {children}
    </SegmentContext.Provider>
  )
}
