import React from 'react'
import AccountLayout from './AccountLayout'
import { AssignedProductsPage } from 'pages/assigned-products'

const AccountAssignedProducts = () => {
  return (
    <AccountLayout page="Assigned Products">
      <AssignedProductsPage />
    </AccountLayout>
  )
}

export default AccountAssignedProducts
