import React, { useCallback, useEffect, useState } from 'react'
import './assignedProducts.css'
import Layout from '../Layout'
import { GridLayout } from '../../components/Utilities/common'
import { LoadingCircleProgress1 } from '../../components/Utilities/progress'
import ProductListContent from 'pages/product/ProductListContent'
import { useProductList } from 'context/product-list-context'
import { fetchSegmentsAssignedProducts } from 'services/segments'
import { TENANT } from 'constants/localstorage'
import { useLegalEntity } from 'context/legal-entity-provider'
import { useSegments } from 'context/segment-provider'
import { useSites } from 'context/sites-provider'
import { useNavigate } from 'react-router-dom'
import { homeUrl } from '../../services/service.config'

export const AssignedProductsPage = () => {
  const [loading, setLoading] = useState(false)
  const { setProductIds, productIds, pageNumber, productsPerPage } =
    useProductList()
  const { currentLegalEntity } = useLegalEntity()
  const { currentSite } = useSites()
  const { segments } = useSegments()
  const navigate = useNavigate()
  const [itemsIds, setItemsIds] = useState(null)
  const [segmentProducts, setSegmentProducts] = useState(null)

  const fetchItems = useCallback(async () => {
    try {
      const tenant = localStorage.getItem(TENANT)
      setLoading(true)
      const newItemsIds = await fetchSegmentsAssignedProducts(
        tenant,
        currentSite,
        currentLegalEntity
      )
      setItemsIds(newItemsIds)
      setSegmentProducts(newItemsIds)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchItems()
  }, [segments])

  useEffect(() => {
    ;(async () => {
      if (segmentProducts) {
        if (segmentProducts?.length === 0) {
          navigate(homeUrl())
        } else {
          setProductIds(itemsIds.map((product) => product.item.id))
        }
      }
    })()
  }, [segmentProducts])

  return (
    <div className="text-center w-full">
      {loading ? (
        <LoadingCircleProgress1 />
      ) : (
        <GridLayout className="justify-end gap-6 mt-7 max-w-screen-lg m-auto text-right">
          <ProductListContent />
        </GridLayout>
      )}
    </div>
  )
}
const AssignedProducts = () => {
  const title = `Assigned Products`
  return (
    <Layout title={title}>
      <div className="assigned-products-page-wrapper">
        <AssignedProductsPage />
      </div>
    </Layout>
  )
}
export default AssignedProducts
