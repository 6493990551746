import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppContext } from './app-context';
import { useSites } from './sites-provider';

const CustomStyleContext = createContext({});

export const useCustomStyleContext = () => useContext(CustomStyleContext)

export const CustomSiteProvider = ({ children }) => {
  const { context } = useAppContext();
  const { currentSite, currentSiteObject } = useSites();
  const [banner, setBanner] = useState(null)
  const [logo, setLogo] = useState(null)
  const [services, setServices] = useState(null)
  const [productCarousel, setPproductCarousel] = useState(null)
  const [subscribe, setSubscribe] = useState(null)
  
  useEffect(() => {
    (async () => {
      const isPreview = localStorage.getItem('preview') === 'true';
      console.log("isPreview", localStorage.getItem('preview'), isPreview)
      const mixinPath = isPreview ? 'previewb2bshowcase' : 'b2bshowcase';
      console.log("mixinPath", mixinPath)
      const globalStyles = currentSiteObject?.mixins?.[mixinPath]?.styles?.global;
      setBanner(currentSiteObject?.mixins?.[mixinPath]?.components?.banner);
      setLogo(currentSiteObject?.mixins?.[mixinPath]?.components?.logo || '/static/media/new_logo.0ac7386956c01dac9adc7a680a321819.svg');
      setServices(currentSiteObject?.mixins?.[mixinPath]?.components?.homeServices);
      setSubscribe(currentSiteObject?.mixins?.[mixinPath]?.components?.subscribe);
      setPproductCarousel(currentSiteObject?.mixins?.[mixinPath]?.components?.productCarousel);

      const styleId = 'emporix-custom-styles';
      const existingStyleElement = document.getElementById(styleId);

      if (existingStyleElement) {
        existingStyleElement.remove()
      }
      if (globalStyles !== undefined) {
        const styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = styleId;
        styleElement.appendChild(document.createTextNode(globalStyles));
        document.head.appendChild(styleElement);
      }
    })();
  }, [currentSite, currentSiteObject]);

  return (
    <CustomStyleContext.Provider value={{
      banner,
      logo,
      services, 
      subscribe,
      productCarousel
    }}>
      {children}
    </CustomStyleContext.Provider>
  );
};
