import { api } from './axios'

export const fetchSegments = async (tenant, site, legalEntity) => {
  const resolvedLegalEntityId = legalEntity?.id ?? ''

  const { data } = await api.get(
    `customer-segment/${tenant}/segments?q=siteCode:${site} status:ACTIVE&legalEntityId=${resolvedLegalEntityId}`,
    {}
  )
  return data
}

export const fetchSegmentsCategoryTrees = async (tenant, site, legalEntity) => {
  const headers = {
    'Accept-Language': '*',
  }
  const resolvedLegalEntityId = legalEntity?.id ?? ''
  const { data } = await api.get(
    `customer-segment/${tenant}/segments/items/category-trees?&siteCode=${site}&legalEntityId=${resolvedLegalEntityId}`,
    { headers }
  )
  return Array.isArray(data) ? data : []
}

export const fetchSegmentsAssignedProducts = async (tenant, site, legalEntity) => {
  const resolvedLegalEntityId = legalEntity?.id ?? ''

  const { data } = await api.get(
    `customer-segment/${tenant}/segments/items?q=type:PRODUCT&siteCode=${site}&legalEntityId=${resolvedLegalEntityId}`,
    {}
  )
  return data
}
