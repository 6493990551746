// URL change listener utility
const setupUrlListener = () => {
  const sendUrlUpdate = () => {
    window.parent.postMessage(
      { currentUrl: window.location.href },
      "*"  // Allow any parent origin
    );
  };

  window.addEventListener('popstate', sendUrlUpdate);

  const originalPushState = window.history.pushState;
  window.history.pushState = function() {
    originalPushState.apply(this, arguments);
    sendUrlUpdate();
  };

  const originalReplaceState = window.history.replaceState;
  window.history.replaceState = function() {
    originalReplaceState.apply(this, arguments);
    sendUrlUpdate();
  };

  sendUrlUpdate();

  return () => {
    window.removeEventListener('popstate', sendUrlUpdate);
    window.history.pushState = originalPushState;
    window.history.replaceState = originalReplaceState;
  };
};

export default setupUrlListener; 